import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { GatsbyImage } from "gatsby-plugin-image";

export default function Contact({ data }) {
  return (
    <Layout>
      <div
      >
        <h2>Page not found</h2>
        <p>Oops! The page you are looking for has been removed or relocated.</p>
        <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} />
      </div>
    </Layout>
  );
}

export const query = graphql`{
  file(relativePath: {eq: "error404.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 680, layout: CONSTRAINED)
    }
  }
}
`
