import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import ClientOnly from "./ClientOnly"

const isActive = propsIfActive => ({ isPartiallyCurrent, href, location }) => {
  if (isPartiallyCurrent) {
    if (href === "/" && location.pathname !== "/") {
      return null
    }

    return propsIfActive
  }

  return null
}

const MenuLink = props => (
  <Link
    to={props.to}
    getProps={isActive({ style: { opacity: 1 } })}
    style={{opacity: 0.6}}
  >
    {props.children}
  </Link>
)

export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="flex flex-col" style={{ minHeight: "100vh" }}>
      <header className="bg-yellow-300 text-black py-3 pride-background">
        <div className="container mx-auto px-6 flex justify-between items-center">
          <Link to="/">
            <h2 className="text-xl font-light" style={{letterSpacing:1}}>{data.site.siteMetadata.title}</h2>
          </Link>
          <nav className="space-x-4">
            <MenuLink to="/">Recipes</MenuLink>
            <MenuLink to="/tags/">Tags</MenuLink>
            <MenuLink to="/faqs/">FAQs</MenuLink>
          </nav>
        </div>
      </header>
      <div className="pt-12 flex-grow flex flex-col">{children}</div>
    </div>
  )
}
